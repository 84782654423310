import React from 'react'
import { animated, config, useSpring } from 'react-spring'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { graphql, Link, useStaticQuery } from 'gatsby'
import imageHydra from '../images/hydra-overview.svg'
import imageHydraLogo from '../images/hydra-logo.svg'
import Layout, { MyPBox } from '../components/layout'
import { Box, Button, AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'
import IconFilePdf from '../icon-file-pdf.svg'
import IconFilePresentation from '../icon-file-presentation.svg'
import IconFileVideo from '../icon-file-video.svg'

const cfg = require('../../config')

const Content = styled(AnimatedBox)<{ bg: string }>`
  background-color: ${props => transparentize(0.9, props.bg)};

  // .gatsby-image-wrapper:not(:last-child) {
  //   margin-bottom: ${props => props.theme.space[10]};
  //
  //   @media (max-width: ${props => props.theme.breakpoints[3]}) {
  //     margin-bottom: ${props => props.theme.space[8]};
  //   }
  // }
 
  .imageBox {
    text-align: center;
  }

  .photoCaption {
    font-size: 0.8rem;
    text-align: center;
    font-style: italic;
  }
  
  .publicationElement {
    margin-bottom: 30px;
  }
  
  .publicationDescription {
  }
  
  .publicationFileList {
    margin-top: 10px;
    margin-left: 25px;
  }
  
  .publicationFileElement {
    margin-top: 5px;
  }
`

const PButtonEmpty = styled(Button)<{ color: string }>`
  background-color: transparent;
`

const SvgIcon = styled.img<{ size: string }>`
  width: ${props => props.size};
  height: ${props => props.size};
`

interface QueryResult {
  publications: {
    edges: {
      node: {
        year: string
        pub: {
          title: string
          author: string
          link?: string
          doi?: string
          venue?: string
          filePdf?: string
          filePdfText?: string
          filePresentation?: string
          filePresentationText?: string
          fileVideo?: string
          fileVideoText?: string
        }
      }
    }[]
  }
  imageTitle: ChildImageSharp
}

const Research = () => {
  const data: QueryResult = useStaticQuery(query)

  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const contentAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })
  const imagesAnimation = useSpring({ config: config.slow, delay: 800, from: { opacity: 0 }, to: { opacity: 1 } })

  return (
    <Layout>
      <SEO title={`Research${cfg.siteTitleSuffix}`} desc="Research page" />
      <AnimatedBox style={titleAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8]}>
        <h1>Research</h1>
      </AnimatedBox>
      <Content style={contentAnimation} bg={cfg.backgroundColor} py={8}>
        <MyPBox>
          <h3>Topic</h3>
        </MyPBox>
        <MyPBox>
          In 2017 I started my PhD in the area of Blockchain-oriented Software Engineering. My focus is on software
          architectures of systems using blockchain components. Current approaches are either too general (those famous
          "Do I need a Blockchain?" flowcharts) or too specific (suggesting custom blockchain implementations down to
          the blocksize and consensus algorithm).
        </MyPBox>
        <MyPBox>
          My goal is to create a pragmatic process that helps software engineers and enterprise architects to decide for
          each element of a system if it would benefit from decentralization (e.g. using blockchain technology) or if it
          should better be implemented using centralized components (e.g. cloud servers) in order to reduce operational
          costs. The result is a hybrid decentralized app (DApp) with the right balance between centralized and
          blockchain components while still fulfilling quality attributes such as data privacy and cost.
        </MyPBox>
        <MyPBox style={imagesAnimation}>
          <Img
            alt="Photo composition from aerial shots showing both a summer and winter landscape as a metaphor for hybrid elements"
            key={data.imageTitle.childImageSharp.fluid.src}
            fluid={data.imageTitle.childImageSharp.fluid}
          />
          <p className="photoCaption">
            Photo composition from aerial shots showing both a summer and winter landscape as a metaphor for hybrid
            systems
          </p>
        </MyPBox>
        <MyPBox>
          <h3>HYDRA</h3>
          <MyPBox className="imageBox" style={imagesAnimation}>
            <img alt="HYDRA Logo" src={imageHydraLogo} width="50%" />
          </MyPBox>
          <p>
            My approach for building decentralized applications is called HYDRA –{' '}
            <i>Hybrid DApp Requirements and Architectural process</i>. The idea is basically to use existing and
            well-known Software Engineering activities, such as identifying actors and goals with use-case diagrams,
            model processes with activity and data flow diagrams, but doing this with a certain blockchain focus in
            mind. The goal is to collect blockchain-relevant information during the requirements engineering phase, e.g.
            data and value flows within the planned system, personal data to protect or strict business processes to
            monitor, and use these insights to make informed and structured decisions for the architectural design.
          </p>
          <MyPBox className="imageBox" style={imagesAnimation}>
            <img alt="Overview of the HYDRA approach" src={imageHydra} width="90%" />
            <p className="photoCaption">Overview of the HYDRA approach</p>
          </MyPBox>
          <p>
            The approach is work in progress as we are currently evaluating and improving the concept in our research
            projects <a href="https://se.wiwi.uni-due.de/forschung/blockcentive/">BlockcENtive</a> and{' '}
            <a href="https://bimcontracts.com">BIMcontracts</a>. Some parts are explained in more detail in the
            publications mentioned below and might be presented on this page in the future :)
          </p>
        </MyPBox>
        <MyPBox>
          <h3>Collaboration</h3>
          <p>
            If you are interested in trying out the HYDRA process for building a commercial product or experimenting
            with blockchain technology within your company, feel free to{' '}
            <Link to="/about" aria-label="Visit about page">
              contact me
            </Link>{' '}
            and we can discuss possible collaborations, e.g. building prototypes or planning research projects.
          </p>
        </MyPBox>
        <MyPBox>
          <h3>Publications</h3>
          <p>
            Here are some of my publications related to blockchain technology. A complete overview can be found on my{' '}
            <a href="https://dblp.uni-trier.de/pers/hd/b/Blum:Florian">DBLP profile</a>.
          </p>
        </MyPBox>
        {data.publications.edges.map(({ node: item }) => (
          <MyPBox>
            <h4>{item.year}</h4>
            {item.pub.map(pub => (
              // title: string
              // author: string
              // link: string
              // doi: string
              // venue: string
              // filePdf: string
              // filePdfText: string
              // filePresentation: string
              // filePresentationText: string
              // fileVideo: string
              // fileVideoText: string
              <>
                <div className="publicationElement">
                  <div className="publicationDescription">
                    <b>{pub.title}</b>, {pub.author}, {pub.venue}
                    {pub.doi && [', ', <a href={pub.link}>{pub.doi}</a>]}
                  </div>
                  <div className="publicationFileList">
                    {pub.filePdf && (
                      <div className="publicationFileElement">
                        <PButtonEmpty>
                          <SvgIcon src={IconFilePdf} size="2rem" />
                        </PButtonEmpty>
                        {pub.filePdfText && <a href={pub.filePdf}>{pub.filePdfText}</a>}
                        {!pub.filePdfText && <a href={pub.filePdf}>pre-print PDF</a>}
                      </div>
                    )}
                    {pub.filePresentation && (
                      <div className="publicationFileElement">
                        <PButtonEmpty>
                          <SvgIcon src={IconFilePresentation} size="2rem" />
                        </PButtonEmpty>
                        {pub.filePresentationText && <a href={pub.filePresentation}>{pub.filePresentationText}</a>}
                        {!pub.filePresentationText && <a href={pub.filePresentation}>presentation slides</a>}
                      </div>
                    )}
                    {pub.fileVideo && (
                      <div className="publicationFileElement">
                        <PButtonEmpty>
                          <SvgIcon src={IconFileVideo} size="2rem" />
                        </PButtonEmpty>
                        {pub.fileVideoText && <a href={pub.fileVideo}>{pub.fileVideoText}</a>}
                        {!pub.fileVideoText && <a href={pub.fileVideo}>video of presentation</a>}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          </MyPBox>
        ))}
      </Content>
      <MyPBox py={10}>
        Feel free to{' '}
        <Link to="/about" aria-label="Visit contact page">
          contact
        </Link>{' '}
        me in case you are interested in discussing any of those topics! :)
      </MyPBox>
    </Layout>
  )
}

export default Research

const query = graphql`
  query ResearchQuery {
    publications: allPublicationsYaml {
      edges {
        node {
          year
          pub {
            title
            author
            link
            doi
            venue
            filePdf
            filePdfText
            filePresentation
            filePresentationText
            fileVideo
            fileVideoText
          }
        }
      }
    }
    imageTitle: file(sourceInstanceName: { eq: "images" }, name: { eq: "hybrid" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
